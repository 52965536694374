<template>
    <div class="liefeng-banner" v-if="banners && banners.length > 0">
        <Carousel class="banner-swiper" :autoplay="true" :autoplay-speed="5000">
            <CarouselItem class="banner-item" v-for="(item, index) in banners" :key="swiperBanner + '-banner-' + index">
                <img :src="item.image" :class="'img ' + swiperBanner + 'bannerImg' + index" :style="'min-height:' + bannerHeight + ';'" @click="bannerClick(item)" />
                <div class="title" v-if="item.title" v-html="item.title"></div>
                <div class="play liefeng-icon liefeng-icon-bofang" v-if="item.video" @click="bannerClick(item)"></div>
            </CarouselItem>
        </Carousel>

        <!-- <swiper
      :ref="swiperBanner"
      :interval="100"
      :class="
        'banner-swiper' +
        (shadow ? ' banner-swiper-shadow' : '') +
        (radius ? ' banner-swiper-radius' : '')
      "
      :circular="circular"
      :indicator-color="indicatorColor"
      :indicator-active-color="indicatorActiveColor"
      :skip-hidden-item-layout="true"
      :indicator-dots="indicatorDots"
      :previous-margin="previousMargin"
      :next-margin="nextMargin"
      :vertical="vertical"
      :style="'height:' + bannerHeight + ';'"
      @change="onChange"
    >
      <swiper-item
        v-for="(item, index) in banners"
        :key="swiperBanner + '-banner-' + index"
        @tap="bannerClick(item)"
      >
        
      </swiper-item>
    </swiper> -->
    </div>
</template>

<script>
/**
 * import Banner from "@/components/Banner";
 * 通用的Banner广告位，点击事件使用：@click=""
 * 应用场景：
 * 1、广告位
 * 2、图片或视频切换：previousMargin和nextMargin的合理应用
 */
export default {
    components: {},
    props: {
        autoplay: { type: Boolean, default: true }, //是否自动切换
        interval: { type: Number, default: 5000 }, //自动切换时间间隔
        indicatorDots: { type: Boolean, default: true }, //是否显示面板指示点
        indicatorColor: { type: String, default: "rgba(255, 255, 255, .5)" }, //指示点颜色
        indicatorActiveColor: { type: String, default: "rgba(200, 0, 0, .5)" }, //当前指示点颜色
        circular: { type: Boolean, default: true }, //是否采用衔接滑动
        vertical: { type: Boolean, default: false }, //滑动方向是否为纵向
        previousMargin: { type: String, default: "0px" }, //前边距，可用于露出前一项的一小部分
        nextMargin: { type: String, default: "0px" }, //后边距，可用于露出后一项的一小部分
        height: { type: Number, default: 320 }, //高度，单位：px
        shadow: { type: Boolean, default: false }, //带阴影
        radius: { type: Boolean, default: false }, //圆角
        banners: {
            //Banner数组
            type: Array,
            default: () => {
                return [
                    // {
                    //   image:"",//图片地址，必选
                    //   title:"",//图片标题，可选
                    //   url:"",//跳转地址，可选
                    // }
                ]
            },
        },
    },
    data() {
        return {
            swiperBanner: "Banner" + this.$core.createID(),
            bannerHeight: "160px",
            showVideo: -1,
        }
    },
    created() {
        this.bannerHeight = this.height
        // if (this.autoplay) setInterval(this.swiperstart, this.interval)
    },

    methods: {
        bannerClick(item) {
            // console.log(item);
            var params = {}
            params.code = item.code
            params.url = item.url
            this.$emit("click", params)
        },
        //处理超高的banner图片高度
        resetImgHeight(e, i) {
            var size = this.$core.getRealSizeNum(this.height)
            var h = e.detail.height
            if (this.$el.clientWidth == 0) {
                //处理因页面切换太快而无法图片大小自适应
                h = this.$core.getRealSizeNum(h)
            }
            if (h > size) {
                this.$("." + this.swiperBanner + "bannerImg" + i).css("margin-top", (size - h) / 2 + "px")
            }
        },
        swiperstart() {
            //自制自动播放功能，解决swiper自动播放的坑，还是有问题
            if (this.banners == null) return
            if (this.$refs[this.swiperBanner]) {
                var current = this.$refs[this.swiperBanner].$el.current || 0
                current++
                if (current > this.banners.length) current = 1
                try {
                    if (current == this.banners.length) {
                        //最后一个为第一个，又是一个坑，需要用播放的方式跳过
                        this.$refs[this.swiperBanner].$el.autoplay = true
                        var that = this
                        this.$nextTick(() => {
                            that.$refs[that.swiperBanner].$el.autoplay = false
                        })
                    } else this.$refs[this.swiperBanner].$el.current = current
                } catch (e) {}
            }
        },
        onChange(e) {
            var cur = e.detail.current
            if (cur >= 0 && cur < this.banners.length) {
                this.$emit("change", this.banners[cur])
            }
        },
    },
}
</script>
<style lang="less">
.liefeng-banner {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;

    .banner-swiper {
        width: 100%;
        height: 100%;
        z-index: 1;

        .ivu-carousel-track {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .img {
            width: 100%;
            height: auto;
            border-radius: 10px;
            cursor: pointer;
        }

        .title {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 3;
            width: calc(100% - 40px);
            padding: 10px 20px;
            background-color: rgba(0, 0, 0, 0.2);
            font-size: 30px;
            // text-shadow: 1px 1px 1px #555;
        }

        .play {
            position: absolute;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
            z-index: 4;
            font-size: 80px;
            color: #fff;
            text-shadow: 1px 1px 1px #eee;
        }
    }
    .banner-swiper-shadow {
        box-shadow: 0px 5px 20px rgba(58, 60, 62, 0.3);
    }
    .banner-swiper-radius {
        border-radius: 8px;
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        transition: all 300ms;
    }
    .swiper-pagination-bullet-active {
        width: 30px;
    }

    .ivu-carousel-list {
        height: 100%;
    }
}
</style>
